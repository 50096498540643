<template>
  <v-container class="general">
    <PageTitle :title="$t('printout_list_page.title')" />
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !printoutTemplates.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("printout_template_list_page.nothing_created") }}
        </v-flex>
      </v-layout>
    </transition>
    <v-layout v-if="!isLoading && printoutTemplates.length" class="row wrap">
      <v-flex class="xs12">
        <v-card
          v-if="printoutTemplates && printoutTemplates.length"
          class="px-4 py-2 border-radius"
          style="width: 100%"
        >
          <v-layout
            v-for="(template, i) in printoutTemplates"
            :key="`printout-template-${i}`"
            class="row wrap align-center"
          >
            <v-flex class="xs11">
              <span>
                <a
                  @click.stop="editPrintoutTemplate(template.id)"
                  class="theme-primary"
                >
                  {{ template.name }}
                </a>
              </span>
              <!--<div class="caption grey--text">{{ role.comment }}</div>-->
            </v-flex>
            <v-flex class="xs1 text-xs-right">
              <v-btn
                icon
                class="theme-primary-bg theme-on-primary"
                :loading="isLoading"
                @click="deletePrintoutTemplate(template.id)"
              >
                <v-icon small color="important">delete</v-icon>
              </v-btn>
            </v-flex>
            <v-flex v-if="showDivider(i)" class="xs12 py-2">
              <v-divider />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <AppSpeedDial
      @click="$router.push({ name: 'printout_templates_create' })"
      :hint="'Create diploma template'"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    printoutTemplates: [],
  }),
  mounted() {
    this.listPrintoutTemplates();
  },
  methods: {
    listPrintoutTemplates() {
      this.isLoading = true;

      this.$api.printoutTemplates.list({ take: 100, skip: 0 }).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.printoutTemplates = response.data;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async deletePrintoutTemplate(id) {
      if (!id) return;

      const template = this.printoutTemplates.find((el) => el.id === id);

      const confirmed = await this.$refs.confirmationDialog.open(
        `Are you sure you want to delete template \"${template.name}\"?`,
        this.$t("no"),
        this.$t("yes"),
      );

      if (!confirmed) return;

      this.isLoading = true;

      this.$api.printoutTemplates.delete(id).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.printoutTemplates = this.printoutTemplates.filter(
            (el) => el.id !== id,
          );

          this.$store.dispatch("addNotification", {
            message: `Template \"${template.name}\" deleted`,
          });
        },
        (error) => {
          this.isLoading = false;
          this.errorMessageShow(error);
        },
      );
    },
    showDivider(index) {
      return this.printoutTemplates.length !== index + 1 ? true : false;
    },
    editPrintoutTemplate(id) {
      this.$router
        .push({
          name: "printout_templates_edit",
          params: { printout_template_id: id },
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss"></style>
